#printCalibration {
    height: 100%;
    margin: 16px 56px;
    font-family: Arial;
    font-size: 12px !important;
}

#printCalibration table, #printCalibration th, #printCalibration td {
    border: 1px solid black;
    border-collapse: collapse;
}

#printCalibration table {
    text-align: center;
}

#printCalibration .v-spacer {
    margin-bottom: 12px;
}

#printCalibration .header {
    font-size: 16px !important;
    margin-bottom: 16px;
    text-align: center;
}

#printCalibration #header.row,
#printCalibration #header .row {
    height: auto;
}

#printCalibration #verticalCaption {
    position: relative;
}

#printCalibration #verticalCaption #sn {
    position: absolute;
    writing-mode: vertical-rl;
    height: 175px;
    right: -45px;
    font-size: 14px;
    text-align: center;
}

#printCalibration #verticalCaption #nist {
    position: absolute;
    writing-mode: vertical-rl;
    height: 500px;
    top: 225px;
    right: -45px;
    font-size: 10px;
    text-align: center;
}

#calibrationInformation .row {
    height: auto;
}

#calibrationChart.row {
    height: 300px;
    margin-top: 12px;
}

#printCalibration .row {
    justify-content: center;
}

#calibrationInformation .row {
    justify-content: flex-start;
}

.inline-block {
    display: inline-block
}

.small-text {
    font-size: 10px;
}

.extra-small-text {
    font-size: 9px;
}

#printCalibration .signature {
    margin-left: 6px;
    border-bottom: 1px solid #00000025;
    height: 100%;
    width: 100%;
}
