body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ma-1 {
  margin: 16px !important;
}

.mt-1 {
  margin-top: 16px !important;
}

.mt-2 {
  margin-top: 32px !important;
}

.mb-2 {
  margin-bottom: 32px !important;
}

.ma-0 {
    margin: 0;
}

.group-box {
  border: 1px solid rgb(243, 242, 241);
  padding: 10px 20px 10px 20px;
}

.group-box .ms-TextField:only-child {
  padding-bottom: 10px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.d-flex {
  display: flex;
}

.mr-1 {
  margin-right: 4px
}

.pr-2 {
  padding-right: 8px;
}

.pt-2 {
  padding-top: 8px;
}

.pa-2 {
  padding: 8px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.align-end {
  align-items: flex-end;
}

.row {
  width: 100%;
  display: flex;
  height: 35px;
}

.v-spacer {
  width: 100%;
  border-top: var(--border-width) solid black;
}

.is-negative .v-spacer {
  border-color: transparent;
}

.column {
  display: flex;
  align-items: center;
}

.text-center {
  text-align: center;
}

.w-default-button {
    min-width: 125px;
}

.w-message-bar {
  cursor: pointer;
  min-width: max-content;
}