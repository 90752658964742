:root {
    --border-width: 3px;
    --grey: #dcdcdc;
}

#mainProperty .v-spacer {
    margin-top: 12px;
}

.shop-card {
    justify-content: center;
    align-items: center;
}

.shop-card > * {
    margin: 24px;
}

#meterSpecification {
    width: calc(50% - (var(--border-width) / 2));
    border-right: var(--border-width) solid black;
}

.is-negative #meterSpecification {
    border-color: transparent;
}

#calibrationInfo {
    width: calc(50% - (var(--border-width) / 2));
}

#calibrationInfo > * {
    padding: 0 0 0 8px;
}

#floMeterOptionsFirst {
    width: calc(50% - (var(--border-width) / 2));
    border-right: var(--border-width) solid black;
    padding: 0 8px 8px 0
}

.is-negative #floMeterOptionsFirst {
    border-color: transparent;
}

#floMeterOptionsSecond {
    width: calc(50% - ((var(--border-width) / 2) - 8px));
}

.shop-card #calibrationInfo .row,
.shop-card #meterSpecification .row {
    align-items: center;
}

.grey-btn {
    height: 25px;
    text-align: center;
    background-color: gainsboro;
    border: 1px solid black;
    padding: 0 16px;
}

.grey-box {
    background-color: gainsboro;
    border: 1px solid black;
    padding: 0 8px;
}

#calibrationInfo .grey-box label {
    font-size: 10px;
}

#calibrationInfo .grey-box .input {
    height: 14px;
}

#calibrationInfo .grey-box input {
    width: 100%;
    margin: 0 0 0 16px;
    font-size: 10px;
    border: none;
}

.shop-card p {
    margin-bottom: 8px;
    margin-top: 8px;
}

.shop-card b {
    margin-right: 8px;
    white-space: nowrap;
}

.shop-card label {
    font-size: 14px;
}

.shop-card .is-negative label {
    color: transparent;
}

.shop-card .is-negative .section-header {
    color: transparent;
}

.shop-card .is-negative .value-label {
    color: transparent;
}

.shop-card .input {
    font-size: 14px;
    border-bottom: 1px solid black;
    height: 20px;
}

.shop-card .is-negative .input {
    border-bottom-color: transparent;
}

.shop-card #floMeterOptions .input,
.shop-card #floMeterOptions label {
    font-size: 12px;
}

#mainProperty .row {
    height: 25px;
}

#calibrationInfo .grey-box .row,
.shop-card #floMeterOptions .row {
    height: 25px
}
