.calibration-table {
    max-width: fit-content;
}

.calibration-table td {
     min-width: 100px;
 }

.calibration-table-headers {
    text-align: center;
}